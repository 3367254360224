import React,{Fragment} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

//views
import Landingpage from './views/landingpage/Landingpage.jsx';
import ProductRegister from './components/ProductRegister/ProductRegister.jsx';
// import Assetlinks from './components/Coolector/Assetlinks.jsx';

function App() {
  React.useEffect(() => {
      window.scrollTo(0, 0)
  }, [])
 

  //TODO: temporary routing while redux is implemented...
  return (
    <Fragment>
      <Router>
      
      <Switch>
        <Route path="/product-register" >
          <ProductRegister/>
        </Route> 
        <Route path="/" exact >
          <Landingpage/>
        </Route>
      </Switch> 
    </Router>
    </Fragment> 
  );
}

export default App;
