import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyD6XvmPdsmSdDd6kplmql-7e4gvVZUEAys",
    authDomain: "solkoslp.firebaseapp.com",
    databaseURL: "https://solkoslp.firebaseio.com",
    projectId: "solkoslp",
    storageBucket: "solkoslp.appspot.com",
    messagingSenderId: "478277975235",
    appId: "1:478277975235:web:280cd962fa91cf41407676"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig); 

  const db = firebase.firestore()
  const auth = firebase.auth()
  const firebaseApp = firebase

  export {db,auth,firebaseApp}