import React,{Fragment} from 'react';

// -------- plugins CSS Files -----------------------//
import './styles.scss';
// -------- plugins JS Files ------------------------//
import './landingpage.js';

import Header from '../../components/Header/Header.jsx';
import Background from '../../components/Background/Background2.jsx';
/* import Items from '../../components/Items/Items.jsx'; */
import About from '../../components/About/About.jsx';
/* import CountData from '../../components/CountData/CountData.jsx'; */
import WhyUs from '../../components/WhyUs/WhyUs.jsx';
import Productivity from '../../components/Productivity/Productivity.jsx';
import Cta from '../../components/Cta/Cta.jsx';
import Services from '../../components/Services/Services.jsx';
/* import Portfolio from '../../components/Portfolio/Portfolio.jsx'; */
import Contact from '../../components/Contact/Contact.jsx'; 
import Footer from '../../components/Footer/Footer.jsx';
import BackToUp from '../../components/BackToUp/BackToUp.jsx';

const Landingpage = () => {
    const consoleurl = "https://console-solkos.web.app/" //"http://localhost:3000/"

    return (
        <Fragment>
            <Header consoleurl={consoleurl}/>
            <Background/>
             <main id="main">
              {/* <Items/> */}
              <About/>
              {/* <CountData/> */}
              <WhyUs/>
              <Productivity/>
              <Cta/>
              <Services/>
              {/* <Portfolio/> */}
              <Contact/>
              </main> 
            <Footer consoleurl={consoleurl}/>
            <BackToUp/>
        </Fragment>
    )
}

export default Landingpage
