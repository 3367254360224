//Languages
export const langSolkos = [
    {"short":"es","full":"Español"},
    {"short":"en","full":"English"},
]

//URL References

//Landing page

//Console
export const consoleUrl = "https://console.solkos.com.mx/"; //"http://localhost:3000/";

//Wifrio

