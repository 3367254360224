import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next'

const Productivity = () => {
  const [t] = useTranslation();
    return (
        <Fragment>
            {/*  ======= Productivity Lists Section =======  */}
            <section id="productivity" className="productivity collapse section-bg">
              <div className="container">

                <div className="row no-gutters">

                  <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up">
                    <span>01</span>
                    <h5>{t("productivity.1.title")}</h5>
                    <p>{t("productivity.1.label")}</p>
                  </div>

                  <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="100">
                    <span>02</span>
                    <h5>{t("productivity.2.title")}</h5>
                    <p>{t("productivity.2.label")}</p>
                  </div>

                  <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="200">
                    <span>03</span>
                    <h5> {t("productivity.3.title")}</h5>
                    <p>{t("productivity.3.label")}</p>
                  </div>

                  <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="300">
                    <span>04</span>
                    <h5>{t("productivity.4.title")}</h5>
                    <p>{t("productivity.4.label")}</p>
                  </div>

                  <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="400">
                    <span>05</span>
                    <h5>{t("productivity.5.title")}</h5>
                    <p>{t("productivity.5.label")}</p>
                  </div>

                  <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="500">
                    <span>06</span>
                    <h5>{t("productivity.6.title")}</h5>
                    <p>{t("productivity.6.label")}</p>
                  </div>

                </div>

              </div>
            </section>{/*  End Productivity list Section  */}
        </Fragment>
    )
}

export default Productivity
