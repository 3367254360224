import {BASE_PATH, API_VERSION} from '../config/config';

export async function totpSecretApi() {

    const url = `${BASE_PATH}/${API_VERSION}/totp-secret`;
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        }
    };
    const response = await fetch(url, params)
    return response.json();

}


export async function totpGenerateApi(data) {

    const url = `${BASE_PATH}/${API_VERSION}/totp-generate`;
    const bodyObj = {
        secret: data.secret
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}

export async function totpValidateApi(data) {

    const url = `${BASE_PATH}/${API_VERSION}/totp-validate`;
    const bodyObj = {
        token: data.token,
        secret: data.secret
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}

export async function totpQrApi(data) {

    const url = `${BASE_PATH}/${API_VERSION}/totp-qr`;
    const bodyObj = {
        userEmail: data.userEmail,
        secret: data.secret
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}



