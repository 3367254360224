import { useState, useEffect } from "react";

export const usePasswordValidation = ({
  
    firstPassword = "",
    secondPassword = "",
    requiredLength = 8,
    numberValidation = true,
    lowercaseValidation = true,
    uppercaseValidation = true,
    specialcharacterValidation = true,
    lengthValidation = true,
  
}) => {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [lowerCase, setLowerCase] = useState(null);
    const [specialChar, setSpecialChar] = useState(null);
    const [match, setMatch] = useState(null);
    const [securePasswordLevel, setSecurePasswordLevel] = useState(0);
    const [securePasswordClass, setSecurePasswordClass] = useState("none")
  
    useEffect(() => {
  
        lengthValidation && setValidLength(firstPassword.length >= requiredLength ? true : false);
        uppercaseValidation && setUpperCase(firstPassword.toLowerCase() !== firstPassword);
        lowercaseValidation && setLowerCase(firstPassword.toUpperCase() !== firstPassword);
        numberValidation && setHasNumber(/\d/.test(firstPassword));
        specialcharacterValidation && setSpecialChar(/\W/.test(firstPassword));
        setMatch(firstPassword && firstPassword === secondPassword);
        setSecurePasswordLevel([validLength, hasNumber, upperCase, lowerCase, specialChar].filter((value) => value).length);
        
        switch (securePasswordLevel) {
            case 1:
                setSecurePasswordClass("danger");
                break;
            case 2:
                setSecurePasswordClass("warning");
                break;

            case 3:
                setSecurePasswordClass("warning");
                break;

            case 4:
                setSecurePasswordClass("warning");
                break;

            case 5:
                setSecurePasswordClass("success");
                break;
        
            default:
                setSecurePasswordClass("none");
                break;
        }
          
    }, [firstPassword,secondPassword,requiredLength,lengthValidation,uppercaseValidation,lowercaseValidation,numberValidation,specialcharacterValidation,securePasswordLevel,validLength, hasNumber, upperCase, lowerCase, specialChar]);
  
    return [validLength, hasNumber, upperCase, lowerCase, match, specialChar,securePasswordLevel,securePasswordClass];
};

