import React,{Fragment} from 'react';
import logo from '../../img/solkos_300x100.jpg';
import {useTranslation} from 'react-i18next';
import {langSolkos,consoleUrl} from '../../utils/constants';

const Header = (props) => {
    const {contentAction} = props
    const [t,i18n] = useTranslation();
  
    
    return (
        <Fragment>
            {/*  ======= Header ======= */}
            <header id="header" className="fixed-top">
              <div className="container">

                <div className="logo float-left">
                  <a href="/"><img src={logo} alt="" className="img-fluid"/></a>
                </div>
                {/* <div className="brandName">
                  <ul>
                    <li><a href="/"><img src={logo} alt="" className="img-fluid"/></a></li>
                    
                  </ul>
                </div> */}
                
                <nav className={`nav-menu float-right d-none ${contentAction ? contentAction: 'd-lg-block'}`}>
                    <ul>
                      <li className="active"><a href="#header">{t("header.home.title")}</a></li>
                      <li><a href="#about">{t("header.about.title")}</a></li>
                      <li><a href="#services">{t("header.services.title")}</a></li>
                      {/* <li><a href="#portfolio">Soluciones</a></li> */}
                      <li><a href="#contact">{t("header.contact.title")}</a></li>
                      
                      <li className="get-started login drop-down"><a href={`${consoleUrl}`} className="service_action">{t("header.login.title")} </a> {/* classname="drop-down" */}
                        <ul className="mt-2">
                            <li><a href={`${consoleUrl}`}>{t("header.login2.title")}</a></li>
                            <li><a href="https://sca.solkos.com.mx/login">{t("header.console.title")}</a></li>
                        </ul>
                      </li>
                      {/* eslint-disable-next-line */} 
                      <li className="drop-down lang cursor"><a href="/#" className="bx bx-world" style={{"fontSize": "21px"}}></a> 
                        <ul className="mt-2">
                          {
                            langSolkos.map(i =>(
                              <>
                                <li>
                                  <span className="link" onClick={() => i18n.changeLanguage(i.short)}>
                                    {i.full}
                                  </span>
                                </li>
                              </>
                            ))
                          }
                        </ul>
                      </li>
                    </ul>
                  </nav>{/* <!-- .nav-menu --> */}

              </div>
            </header>{/* <!-- End Header --> */}
        </Fragment>
    )
}

export default Header
