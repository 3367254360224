import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const WhyUs = () => {
    const [t] = useTranslation();
    return (
        <Fragment>
            {/*  ======= Why Us Section =======  */}
            <section id="why-us" className="why-us section-bg">
            <div className="container">

                <div className="row">
                <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-right">
                    <div className="content">
                    <h3>{t("whyus.card1.title")}</h3>
                    <p>
                    {t("whyus.card1.label")}
                    </p>
                    <div className="text-center whyUs-btn">
                        <a href="#productivity" data-toggle="collapse" aria-expanded="false" aria-controls="productivity" className="more-btn">{t("common.know.more")} <i className="bx bx-chevron-right"></i></a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-8 d-flex align-items-stretch">
                    <div className="icon-boxes d-flex flex-column justify-content-center">
                    <div className="row">
                        <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon-box mt-4 mt-xl-0">
                            <i className='bx bx-cctv'></i>
                            <h4>{t("whyus.card2.title")}</h4>
                            <div className="row">
                                <div className="col">
                                <hr></hr>
                                    <p>
                                    {t("whyus.card2.label")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon-box mt-4 mt-xl-0">
                            <i className='bx bx-error'></i>
                            <h4>{t("whyus.card3.title")}</h4>
                            <div className="row">
                                <div className="col">
                                    <hr></hr>
                                    <p>
                                    {t("whyus.card3.label")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon-box mt-4 mt-xl-0">
                            <i className='bx bx-line-chart-down'></i>
                            <h4>{t("whyus.card4.title")}</h4>
                            <div className="row">
                                <div className="col">
                                    <hr></hr>
                                    <p>
                                    {t("whyus.card4.label")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>{/*  End content  */}
                </div>
                </div>

            </div>
            </section>{/*  End Why Us Section  */}
        </Fragment>
    )
}

export default WhyUs
