import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const Services = () => {
    const [t] = useTranslation();
    return (
        <Fragment>
            {/*  ======= Services Section =======  */}
            <section id="services" className="services section-bg">
            <div className="container">

                <div className="row">
                <div className="col-lg-4">
                    <div className="section-title" data-aos="fade-right">
                    <h2>{t("services.1.title")}</h2>
                    <p>
                        {t("services.1.label")}
                    </p>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="row">
                    <div className="col-md-6 d-flex align-items-stretch">
                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon"><i className="fas fa-percent"></i></div>
                        <h4><a href="/#">{t("services.2.title")}</a></h4>
                        <p>
                            {t("services.2.label")}
                        </p>
                        </div>
                    </div>

                    <div className="col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon"><i className="fas fa-search"></i></div>
                        <h4><a href="/#">{t("services.3.title")}</a></h4>
                        <p>
                            {t("services.3.label")}
                        </p>
                        </div>
                    </div>

                    {/* <div className="col-md-6 d-flex align-items-stretch mt-4">
                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon"><i className="fas fa-link"></i></div>
                        <h4><a href="/#">Connect with your customers</a></h4>
                        <p>Establish solid relationships with your clients and encourage competitiveness and communication through activity-based scoring systems
                        </p>
                        </div>
                    </div> */}

                    <div className="col-12 d-flex mt-4">
                        <div className="row icon-box" data-aos="zoom-in" data-aos-delay="400">
                        <div className="col-6">
                            <div className="icon"><i className="fas fa-bullhorn"></i></div>
                            <h4><a href="/#">{t("services.4.title")}</a></h4>
                        </div>
                        <div className="col-6">
                            <p>
                                {t("services.4.label")}
                            </p>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>

            </div>
            </section>{/*  End Services Section  */}
        </Fragment>
    )
}

export default Services
