import {BASE_PATH, API_VERSION} from '../config/config';

export async function checkProductsApi(data) {

    const url = `${BASE_PATH}/${API_VERSION}/check-products`;
    const bodyObj = {
        serie: data.serie,
        userId: data.userId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}

export async function checkProductsErp(data) {
    // data.userId
    const url = `https://us-central1-imberalink-238816.cloudfunctions.net/erp-serie-validator`;
    const bodyObj = {
        "serie": data.serie
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        },
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    console.log(response);
    return response.text();

}

export async function registerProducts(data) {

    const url = `${BASE_PATH}/${API_VERSION}/register-products`;
    const bodyObj = {
        serie: data.serie,
        userId: data.userId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
}

//Register device on CirWifi
/* export async function registerProductsCirWifi(data) {

    const url = `${BASE_PATH}/${API_VERSION}/register-products`;
    const bodyObj = {
        serie: data.serie,
        userId: data.userId
    };
    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json",
        }
    };
    // console.log(data,url);
    const response = await fetch(url, params)
    return response.json();
} */