import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import bg1 from '../../img/bg/enfriadores-black-bg.jpg';
import bg2 from '../../img/bg/cooling-bg.png';
import bg3 from '../../img/bg/enfriador-white-bg.jpg';

const Background2 = () => {
  const [t] = useTranslation()
    return (
        <Fragment>
            {/*  Hero Section */}
            <section id="hero">
              <div className="hero-container">
                <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">

                  <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                  <div className="carousel-inner" role="listbox">

                    {/*  Slide 1  */}
                    <div className="carousel-item active" style={{backgroundImage: "url(" + bg1 + ")"}}>
                      <div className="carousel-container">
                        <div className="carousel-content container">
                          <h2 className="animate__animated animate__fadeInDown">{t("background.slide1.1.title")}<br></br>{t("background.slide1.2.title")}</h2>
                          <p className="animate__animated animate__fadeInUp">{t("background.slide1.label")}</p>
                          <a href="#why-us" className="btn-get-started animate__animated animate__fadeInUp scrollto">{t("common.know.more")}</a>
                        </div>
                      </div>
                    </div>

                    {/*  Slide 2  */}
                    <div className="carousel-item" style={{backgroundImage: "url(" + bg2 + ")"}}>
                      <div className="carousel-container">
                        <div className="carousel-content container">
                          <h2 className="animate__animated animate__fadeInDown">{t("background.slide2.title")}</h2>
                          <p className="animate__animated animate__fadeInUp">{t("background.slide2.label")}</p>
                          <a href="#cta" className="btn-get-started animate__animated animate__fadeInUp scrollto">{t("common.know.more")}</a>
                        </div>
                      </div>
                    </div>

                    {/*  Slide 3 */}
                    <div className="carousel-item" style={{backgroundImage: "url(" + bg3 + ")"}}>
                      <div className="carousel-container">
                        <div className="carousel-content container">
                          <h2 className="animate__animated animate__fadeInDown">{t("background.slide3.1.title")}<br></br>{t("background.slide3.2.title")}</h2>
                          <p className="animate__animated animate__fadeInUp">{t("background.slide3.label")}</p>
                          <a href="#services" className="btn-get-started animate__animated animate__fadeInUp scrollto">{t("common.know.more")}</a>
                        </div>
                      </div>
                    </div>
                    
                  </div>

                  <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon bx bxs-chevron-left" aria-hidden="true"></span>
                    <span className="sr-only">{t("common.before")}</span>
                  </a>
                  <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon bx bxs-chevron-right" aria-hidden="true"></span>
                    <span className="sr-only">{t("common.after")}</span>
                  </a>
                </div>
              </div>
            </section>{/* End Hero */}
        </Fragment>
    )
}

export default Background2
