import React,{Fragment} from 'react';
import { /* db, */ auth } from "../../firebase";
import {useTranslation} from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import bg3 from '../../img/bg/enfriador-white-bg.jpg';
import logo from '../../img/solkos_300x100.jpg';

// -------- plugins CSS Files -----------------------//
import './styles.scss';
// -------- plugins JS Files ------------------------//
import '../../views/landingpage/landingpage.js';

import Header from '../Header/Header.jsx';
import Footer from '../Footer/Footer.jsx';
import BackToUp from '../BackToUp/BackToUp.jsx';

import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { signinApi,signinCirWifiApi, loginCirWifiApi,findUserByIdApi, updateUser2faApi, createUserWithFirebase,logInWithFirebase, currentUserFirebase } from '../../api/controllers/user'
import { totpSecretApi,totpValidateApi,totpQrApi } from '../../api/controllers/otp'
import { checkProductsErp, checkProductsApi, registerProducts } from '../../api/controllers/products'

const ProductRegister = (props) => {
    const [t] = useTranslation()
    /* $('html, body').animate({
      scrollTop: 0
    }, 10, 'easeInOutExpo'); */

    //Hooks initial state
    const alertInitialState = [{display: "invisible",type: "",principalMsg: "",message: "",},];

    //Hooks
    const [error, setError] = React.useState(null);
    const [userId, setUserId] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [webPwd, setWebPwd] = React.useState("");
    const [webPwd2, setWebPwd2] = React.useState(""); // confirm pwd for web login
    const [token2FA, setToken2FA] = React.useState("");
    const [secret, setSecret] = React.useState("");
    const [hasSecret,setHasSecret] = React.useState(false);
    const [phoneNum, setPhoneNum] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [formStat, setFormStat] = React.useState(0); // 0 -> userexist , 1 -> signin, 2 -> login (verify the user, update and logout) , 3 -> goToLogin
    const [contentAlert, setContentAlert] = React.useState(alertInitialState); //for server success notifications
    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState([])
    const [qrImage, setQrImage] = React.useState("");

    //Variables
    const delay = 5000;
    const phoneno = /^\+?([0-9]{1,4})\)?[(]?([0-9]{3})[)]?([0-9]{3})[-]?([0-9]{4})$/; //regular expression for validate phone number
    const consoleUrl = "http://console.solkos.com.mx/"; //"http://localhost:3002/login"; //
    //Methods

    const [
      validLength,hasNumber,
      upperCase,lowerCase,
      match,specialChar,
      securePasswordLevel,
      securePasswordClass
    ] = usePasswordValidation({
      firstPassword: webPwd,
      secondPassword: webPwd2,
      requiredLength: 8,
      // numberValidation: false
    });

    const processError = (error) => {
        setError(error);
        setTimeout(function () {
          setError(null);
        }, delay);
      };
    
      let processAlert = (type, principalMsg, message) => {
        setContentAlert([
          {
            type: type,
            display: "show",
            principalMsg: principalMsg,
            message: message,
          },
        ]);
        setTimeout(function () {
          setContentAlert(alertInitialState);
        }, delay);
      };

    // > Called by form-submit
    const processData = (e) => {
      e.preventDefault();

      //inputs validations
      if (!email.trim() && formStat <= 1) {processError("Enter an email");return; }
      if (!username.trim() && formStat === 0){processError("Please enter a username");return; }
      if (!lastname.trim() && formStat === 0){processError("Please enter at least one last name");return; }
      if (!phoneNum.trim() && formStat === 0 && (phoneNum.trim().length < 10)) {processError("Please enter a valid phone number");return; }
      if (!webPwd.trim() && formStat <= 1) {processError("Enter your password");return;}
      if (!validLength && formStat === 0) {processError("Enter password of 8 characters or more");return; }
      if (!hasNumber && formStat === 0) { processError("Passwords must have numbers");return;}
      if (!upperCase && formStat === 0) { processError("Passwords must have uppercase");return;}
      if (!lowerCase && formStat === 0) { processError("Password must have lowercase");return;}
      if (!specialChar && formStat === 0) { processError("Passwords hasn't special characters");return;}
      if (!match && formStat === 0) { processError("Passwords do not match");return;}
      
      
      
      if (!products.length > 0 && formStat === 2) {processError("Enter at least one product id");return; }
      

      setLoading(true);
      //console.log(props)
      if (formStat === 0) {
        userSignin();
      } else if (formStat === 1) {
        loginWithEmail();
      } else if(formStat === 2){
        syncQr();
      }else{
        window.location.replace(consoleUrl);
      }
    };

    //Create new user - add products
    const userSignin = React.useCallback(async () => {

        try{
          // const res = await auth.createUserWithEmailAndPassword(email, webPwd);
          const res = await createUserWithFirebase({email:email,password:webPwd});
          //console.log(res.user);
          await signinApi({
            id: res.user.uid,
            email:email,
            phone:phoneNum,
            phoneStatus: 0,
            password: webPwd,
            repeatPassword: webPwd2,
          });

          const cirwifiToken = await signinCirWifiApi({
            email:email,
            password: webPwd,
          });
    
          localStorage.setItem("cirwifiToken",cirwifiToken)

          setUserId(res.user.uid)
          const tempSecret = await totpSecretApi()
          const qruri = await totpQrApi({userEmail:email,secret:tempSecret.secret})
          setQrImage(qruri)
          setSecret(tempSecret.secret)

          setEmail("")
          setUsername("")
          setLastname("")
          setPhoneNum("")
          setWebPwd("")
          setWebPwd2("")
          setFormStat(2)

          auth.signOut();
        }catch(err){
          console.log(err);
          var errorCode = err.code;
          //var errorMessage = error.message;
          if (errorCode === "auth/invalid-email") {
            processAlert("alert-error", "Error", `Invalid email`);
            //processError("Invalid email");
          } else if (errorCode === "auth/email-already-in-use") {
            processAlert("alert-error", "Error", `Email already registered`);
            //processError("Email already registered");
          }
          auth.signOut();
        }

      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, username, lastname, webPwd, webPwd2, phoneNum]);

    //Login
    const loginWithEmail = React.useCallback(async () => {
      
          try {
            // await auth.signInWithEmailAndPassword(email, webPwd);
            await logInWithFirebase({email:email, password:webPwd});
      
            // const user = auth.currentUser;
            const user = await currentUserFirebase()
            if(user.uid){
              setUserId(user.uid)
              const userData = await findUserByIdApi(user.uid)

              const cirwifiToken = await loginCirWifiApi({email:email, password:user.uid})
              localStorage.setItem("cirwifiToken",cirwifiToken)

              if(userData.two_factor_enabled === false){
                const tempSecret = await totpSecretApi()
                const qruri = await totpQrApi({userEmail:email,secret:tempSecret.secret})
                setQrImage(qruri)
                setSecret(tempSecret.secret)
              }else{
                setSecret(userData.two_factor_secret)
                setHasSecret(true)
              }
              
              setEmail("")
              setUsername("")
              setLastname("")
              setPhoneNum("")
              setWebPwd("")
              setWebPwd2("")
              setFormStat(2)
            }else{
              processError(`Error getting the user information`);
            }
            auth.signOut();
          } catch (error) {
            console.log(error);
            var errorCode = error.code;
            //var errorMessage = error.message;
            if (errorCode === "auth/invalid-email") {
              processError(`Invalid email`);
            } else if (errorCode === "auth/user-not-found") {
              processError("Unregistered user");
            } else if (errorCode === "auth/wrong-password") {
              processError("Incorrect password");
            } else {
              processError(error.message);
            }
            auth.signOut();
          }


      
      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, webPwd]);

    //Login
    const syncQr = React.useCallback(async () => {

      const verifyToken2FA = await totpValidateApi({token:token2FA,secret:secret})

      if(verifyToken2FA.valid === true){
        await updateUser2faApi({secret,userId})

        const checkProductsE = await checkProductsErp({
          "serie": products
        })
        const checkProductsA = await checkProductsApi({
          "serie": products
        });
  
        try {
          if(checkProductsE === "1" && checkProductsA === 1){
            try {
              await registerProducts({
                "serie": products,
                "userId": userId,
              });
                
              processAlert("alert-success", "Success", `Account updated successfully`);
  
              setFormStat(3)  
          
            } catch (error) {
              //no se pudo actualizar la información
              console.log(error)
              processError("Unexpected error, the information could not be updated");
            }
          }else {
            // processError(`verify that all your serial numbers are correct`);
            processAlert("alert-error", "Error", `verify that all your serial numbers are correct`);
          }
        } catch (error) {
          processAlert("alert-error", "Error", `We could not verify the serial numbers`);
        }
      }else{
        processAlert("alert-error", "Error", `your 6 digit token was wrong`);
      }

      setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products,userId,secret,token2FA])

    return (
      <Fragment>
        <Header contentAction="d-none" />
        <main id="main">
          {/* ======= Breadcrumbs Section ======= */}
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Products registration</li>
                </ol>
              </div>
            </div>
          </section>
          {/*  End Breadcrumbs Section  */}


          <section id="hero">
              <div className="hero-container">
                <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">
                  <div className="carousel-inner" role="listbox">

                    {/*  Slide 1  */}
                    <div className="carousel-item active" style={{backgroundImage: "url(" + bg3 + ")"}}>
                      <div className="carousel-container sty-product">
                        <div className="carousel-content container">

                            <div className="container">
                              <div className="stickyAlert">
                                <div className={`toast ${contentAlert[0].display}`}>
                                    <div className="toast-header">
                                        <div className={`rounded mr-2 ${contentAlert[0].type}`}></div>
                                        <strong className="mr-auto">{contentAlert[0].principalMsg}</strong>
                                        <small>Durará {delay/1000} segundos</small>
                                        <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"
                                        onClick={() => setContentAlert(alertInitialState)}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="toast-body">
                                    {contentAlert[0].message}
                                    </div>
                                </div>
                              </div>
                              {/* content */}
                              <div className="sty-textProductRegister">
                                <div className="text-holder">
                                    <div className="content-holder-text">
                                        <h1 style={{"color":"#fff"}}>Products Registration</h1>
                                        <p>Register your Solkos products for faster, more convenient product support and to receive updates about your products.</p>
                                    </div>
                                </div>                               
                              </div>
                              <div className="d-block text-center"><i className="bx bx-fridge sty-iconProdct"></i></div>
                            </div>
          

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
          </section>
          <section className="registration">
          <div className="sty-productRegister container">
            <div className="row">
              <div className="about-registration col-lg-6 col-12">
                <div><h3>Registration</h3></div>
                <ul>
                  <li className="row">
                    <div className="col-12 col-md-2 icon "><i className="bx bx-message-square-detail" style={{"fontSize": "40px"}}></i></div>
                      <div className="col-12 col-md-10 ">
                        <h5>Product Updates and Information</h5>
                        <p>We'll alert you about important product information.</p>
                      </div>
                  </li>
                  <li className="row">
                    <div className="col-12 col-md-2 icon "><i className="bx bx-support" style={{"fontSize": "40px"}}></i></div>
                      <div className="col-12 col-md-10 ">
                        <h5>Service and Support</h5>
                        <p>Get faster service when you need it. Please note that failure to complete and submit this form does not diminish your warranty rights.</p>
                      </div>
                  </li>
                </ul>
              </div>
              <div className="form-registration col-lg-6 col-12">
                {(<div className={` sty-specialAlert alert alert-danger ${error ? '': 'invisible'}`}>{error ? error: 'invisible'}</div>)}
                <div className="logo">
                  <a href="http://localhost:3002/login" className="cursor"><img src={logo} alt="" className="img-fluid"/></a>
                </div>
                <form onSubmit={processData} className="loginForm">
                  <div className={`${formStat <=1 ? '' : 'd-none'}`}>
                    <div className={`input-group pb-2`}>
                      <label className="col-12">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        className="form-control sty-inputForm col-12"
                        placeholder={`${formStat === 0 ? "Register your favorite email" : "Enter your email"}`} 
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                  <div className={`row ${formStat !==0 ? 'd-none': ''}`}>
                    <div className={`input-group col-6 pr-1`}>
                      <label className="col-12">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control sty-inputForm col-12"
                        placeholder="Enter your name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className={`input-group col-6 pl-1`}>
                      <label className="col-12">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control sty-inputForm col-12"
                        placeholder="Enter your surname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={`mt-2 pb-2 ${formStat !== 0 ? 'd-none' : ''}`}>
                      <label className="col-12">
                        Phone
                      </label>
                    <PhoneInput
                      inputClass={`w-100 sty-invalidPhone `}
                      enableSearch={true}
                      autoFormat={true}
                      searchStyle={{ width: "93%","borderRadius": "10px" }}
                      country={"us"}
                      value={phoneNum}
                      searchNotFound="No data to display"
                      inputProps={{ id: "phoneInput" }}
                      onChange={
                        value => setPhoneNum('+'+value)
                      }
                      isValid={(value, country) => {
                        if (value.match(phoneno)) {
                          const phone = document.getElementById("phoneInput").value.replace(/-| |\(|\)/g, "").toString();
                          const planePhone = phone.replace("+" + country.countryCode,"");
                          if (planePhone.length !== 10) {
                            return "Invalid phone";
                          }
                        } else {
                          return "Invalid phone";
                        }
                      }}
                    />
                  </div>
                  <div className={`input-group pb-2 ${formStat <= 1 ? '' : 'd-none'}`}>
                    <label className="col-12">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control sty-inputForm"
                      placeholder={`${formStat === 0 ? "Define a strong password" : "Enter your password"}`}
                      value={webPwd}
                      onChange={(e) => setWebPwd(e.target.value)}
                    />
                  </div>

                  <div class={`input-password__marker ${formStat !==0 ? 'd-none' : ''}`}>
                    <div class={`${securePasswordClass}`}></div>
                    <div class={`${securePasswordLevel > 1 ? securePasswordClass : "none"}`}></div>
                    <div class={`${securePasswordLevel > 2 ? securePasswordClass : "none"}`}></div>
                    <div class={`${securePasswordLevel > 4 ? securePasswordClass : "none"}`}></div>
                  </div>

                  <div className={`input-group pb-2 ${formStat !==0 ? 'd-none' : ''}`}>
                    <label className="col-12">
                      Repeat your password
                    </label>
                    <input
                      type="password"
                      className="form-control sty-inputForm"
                      placeholder="Confirm that you remember your password"
                      value={webPwd2}
                      onChange={(e) => setWebPwd2(e.target.value)}
                    />
                  </div>

                  <div className={`form-group pb-2 ${formStat === 2 ? '' : 'd-none'}`}>
                    <div className={`panelSolkos row ${hasSecret?'d-none':''}`}>
                      <div className="col-6 mt-auto mb-2"><p style={{"textAlign":"initial"}}>Scan this QR Code image with Google Authenticator, Authy or any other compatible 2FA app.</p></div>
                      <div className="col-6 pl-0"><img alt="QR Code" src={`${qrImage}`}/></div>
                      
                      
                    </div>
                    
                    <label className="col-12" for="productTextArea">
                      Register your products
                    </label>
                    <ReactTagInput 
                      id="productTextArea"
                      className="form-control"
                      tags={products} 
                      maxTags={500}
                      placeholder="Type and press enter"
                      editable={true}
                      readOnly={false}
                      removeOnBackspace={true}
                      onChange={(product) => setProducts(product)}
                      validator={(value) => {
                        // Don't actually validate e-mails this way
                        const existInList = products.indexOf(value) === -1;
                        if (!existInList) {
                          processAlert("alert-warning", "Warning", `the serial number: ${value}, has already been written`);
                        }
                        // Return boolean to indicate validity
                        return existInList;
                      }}
                    />
                    <label className="col-12">
                      enter the one-time code
                    </label>
                    <input
                      type="text"
                      className="form-control sty-inputForm"
                      placeholder="6 digit code"
                      value={token2FA}
                      onChange={(e) => setToken2FA(e.target.value)}
                    />
                    
                  </div>
                  <div className={`${formStat === 3 ? '' : 'd-none'}`}>
                    <div className="panelSolkos" >
                      <div><i className="bx bxs-check-circle" style={{"fontSize":"99px"}}></i></div>
                     <span>Your product has been successfully registered</span>
                    </div>
                  </div>
                  
                  <span onClick={() => { formStat === 0 ? setFormStat(1) : setFormStat(0) }} className={`${formStat > 1 ? 'invisible' : ''} cursor sty-changeFormat`}>{formStat === 0 ? "I already have an account" : "Create a new account" }</span>
                  <button
                    id="loginSubmit"
                    className={` btn btn-block loginSubmit ${
                      loading ? "disabled" : ""
                    }`}
                    type="submit"
                  >
                    {loading ? (
                      <i className="bx bx-loader-alt loadElement"></i>
                    ) : (
                      <span className="centerSpan">
                        { formStat === 0
                          ? "Register"
                          : formStat === 1 || formStat === 2
                          ? "Continue"
                          : "Go to login" }
                      </span>
                    )}
                  </button>
                </form>
              </div>
            </div>
            
          </div>
          </section>
        </main>
        <Footer />
        <BackToUp />
      </Fragment>
    );
}

export default ProductRegister
