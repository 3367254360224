import React,{Fragment} from 'react';
import serviceConditions from '../../assets/Condiciones-Solkos.pdf';
//import privacyPolicy from '../../assets/files/aviso-privacidad.html';
import {useTranslation} from 'react-i18next';
import {langSolkos, consoleUrl} from '../../utils/constants';

const Footer = () => {
    const [t,i18n] = useTranslation();

    return (
        <Fragment>
            {/* ======= Footer ======= */}
            <footer id="footer">

                <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-auto col-md-6 col-sm-12 mr-auto footer-contact">
                            <h3>{t("common.solkos")}</h3>
                            <p>
                            {t("footer.location.label")}<br/>
                            {t("footer.location.state.label")}<br/>
                            {t("footer.location.country.label")} <br/><br/>
                            <strong>{t("contact.phone.title")}</strong>{t("contact.phone.label")}<br/>
                            <strong>{t("contact.email.title")}</strong> {t("contact.email.label")}<br/>
                            </p>
                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-12 sty-footer">
                            <div className=" col-sm-12 col-md-6 footer-links">
                                <div>
                                    <h4>{t("footer.interest.links.title")}</h4>
                                    <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a className="scrollto" href="#header">{t("header.home.title")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a className="scrollto" href="#about">{t("header.about.title")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a className="scrollto" href="#services">{t("header.services.title")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a className="scrollto" href="#contact">{t("header.contact.title")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a className="scrollto" href="/aviso-privacidad/aviso-privacidad-solkos.html" target ="_blank" >{t("footer.privacy.policy")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a className="scrollto" href="/aviso-privacidad-coolector/aviso-privacidad-coolector.html" target ="_blank" >{t("footer.privacy.policy-coolector")}</a></li>
                                    </ul>
                                </div>

                            </div>

                            <div className=" col-sm-12 col-md-6 footer-links">
                                <div>
                                    <h4>{t("footer.user.links.title")}</h4>
                                    <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="/product-register" className="linkedin">{t("footer.user.links.product.register")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href={`${consoleUrl}`}>{t("footer.user.links.user.login")}</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href={serviceConditions} target ="_blank" rel="noopener noreferrer">{t("footer.user.links.terms.service")}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
                </div>

                <div className="container d-md-flex py-4">

                <div className="mr-md-auto text-center text-md-left d-flex">
                    <div className="languaje">
                        <span className="link" onClick={() => i18n.changeLanguage(langSolkos[0].short)}>{langSolkos[0].short}</span> |
                        <span className="link" onClick={() => i18n.changeLanguage(langSolkos[1].short)}>{langSolkos[1].short}</span>
                    </div>
                    <div className="copyright pl-2">
                        &copy; {t("footer.copyright.label")} <strong><span>{t("common.solkos")}</span></strong>{t("footer.copyright.2.label")}
                    </div>
                    
                </div>
                <div className="social-links text-center text-md-right pt-3 pt-md-0">
                    {/* <a href="/#" className="twitter"><i className="bx bxl-twitter"></i></a>
                    <a href="/#" className="facebook"><i className="bx bxl-facebook"></i></a>
                    <a href="/#" className="instagram"><i className="bx bxl-instagram"></i></a>
                    <a href="/#" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                    <a href="https://www.linkedin.com/in/solkos/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
                </div>
            </footer>{/*  End Footer  */}
        </Fragment>
    )
}

export default Footer
