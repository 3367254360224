import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// -------- Icon files ----------------------//
import 'boxicons/css/boxicons.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
// -------- CSS Files -----------------------//
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/general.scss';
// ---------- JS Files ---------------------//
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery.easing/jquery.easing.min.js';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
