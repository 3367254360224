import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const Contact = () => {
    const [t] = useTranslation();
    return (
        <Fragment>
            {/*  ======= Contact Section =======  */}
            <section id="contact" className="contact">
            <div className="container">
                <div className="row">
                <div className="col-lg-4" data-aos="fade-right">
                    <div className="section-title">
                    <h2>{t("contact.title")}</h2>
                    <p>
                    {t("contact.1.label")}<br></br><br></br>
                    {t("contact.2.label")}<br></br>
                    {t("contact.3.label")}<br></br>
                    {t("contact.4.label")}
                    
                    </p>
                    </div>
                </div>

                <div className="col-lg-8" data-aos="fade-up" data-aos-delay="100">
                    <iframe style={{border:"0", width: "100%", height: "270px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3739.6406871952126!2d-99.98452298638162!3d20.397699614399816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d30b81d1ab57a5%3A0x4c6043e9271214fb!2sImbera%20Latinoam%C3%A9rica!5e0!3m2!1ses!2sbg!4v1600704621662!5m2!1ses!2sbg" frameBorder="0" allowFullScreen="" title="solkos"></iframe>
                    <div className="info mt-4">
                    <i className="bx bxs-map"></i>
                    <h4>{t("costact.location.title")}</h4>
                    <p>{t("contact.location.label")}</p>
                    </div>
                    <div className="row">
                    <div className="col-lg-6 mt-4">
                        <div className="info">
                        <i className="bx bxs-envelope   "></i>
                        <h4>{t("contact.email.title")}</h4>
                        <p>{t("contact.email.label")}</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="info w-100 mt-4">
                        <i className="bx bxs-phone"></i>
                        <h4>{t("contact.phone.title")}</h4>
                        <p>{t("contact.phone.label")}</p>
                        </div>
                    </div>
                    </div>
                    {/* 
                    <form action="callback view or method" method="post" role="form" className="php-email-form mt-4">
                    <div className="form-row">
                        <div className="col-md-6 form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div className="validate"></div>
                        </div>
                        <div className="col-md-6 form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                        <div className="validate"></div>
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div className="validate"></div>
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                        <div className="validate"></div>
                    </div>
                    <div className="mb-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                    </form> */}
                </div>
                </div>

            </div>
            </section>{/*  End Contact Section  */}
        </Fragment>
    )
}

export default Contact
