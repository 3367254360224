import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const Cta = () => {
    const [t] = useTranslation();
    return (
        <Fragment>
            {/*  ======= Cta Section =======  */}
            <section id="cta" className="cta">
            <div className="container">

                <div className="text-center" data-aos="zoom-in">
                <h3>{t("cta.title")}</h3>
                <p>
                    {t("cta.label")}
                </p>
                <a className="cta-btn scrollto" href="#contact">{t("cta.contact")}</a>
                </div>

            </div>
            </section>{/*  End Cta Section  */}
        </Fragment>
    )
}

export default Cta
