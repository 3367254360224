import React from 'react';
import {useTranslation} from 'react-i18next';
import ModalVideo from 'react-modal-video';
import solkosCooler from '../../img/solkosSpace.PNG';


const About2 = () => {
    const [t] = useTranslation()
    const [isOpen, setOpen] = React.useState(false)
    return (
        <>
           {/*  ======= About Us Section ======= */}
            <section id="about" className="about">
              <div className="container">

                <div className="row no-gutters">
                  <div className="col-lg-6 video-box">
                    <img src={solkosCooler} className="img-fluid" alt=""/>
                    <i className="play-btn mb-4" onClick={()=> setOpen(true)}></i>
                    <ModalVideo channel='youtube' isOpen={isOpen} className="play-btn mb-4" videoId="ZUazZ566Ae0" autoplay="1" onClose={() => setOpen(false)} />
                  </div>

                  <div className="col-lg-6 d-flex flex-column justify-content-top about-content">

                    <div className="section-title">
                      <h2>{t("header.about.title")}</h2>
                      <p>
                        {t("about.info.label")}
                      </p>
                    </div>

                  </div>
                </div>

              </div>
            </section>{/*  End About Us Section */} 
        </>
    )
}

export default About2
