import React,{Fragment} from 'react';

const BackToUp = () => {
    return (
        <Fragment>
            <a href="/#" className="back-to-top"><i className="bx bxs-chevron-up"></i></a>
        </Fragment>
    )
}

export default BackToUp
